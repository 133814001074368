import pino, { Logger as PinoLogger } from "pino";

class CustomLogger {
  private pino: PinoLogger;

  constructor() {
    this.pino = pino({
      level: "debug",
      timestamp: pino.stdTimeFunctions.isoTime,
      browser: {
        asObject: true,
        write: (o) => console.log(JSON.stringify(o)),
      },
    });
  }

  private _log(
    level: "info" | "error" | "debug" | "warn" | "trace" | "fatal",
    ...args: any[]
  ): void {
    if (args.length === 1) {
      this.pino[level](args[0]);
    } else {
      const message = args
        .map((arg) =>
          typeof arg === "object" ? JSON.stringify(arg) : String(arg),
        )
        .join(" ");
      this.pino[level](message);
    }
  }

  info(...args: any[]): void {
    this._log("info", ...args);
  }

  error(...args: any[]): void {
    this._log("error", ...args);
  }

  debug(...args: any[]): void {
    this._log("debug", ...args);
  }

  warn(...args: any[]): void {
    this._log("warn", ...args);
  }

  trace(...args: any[]): void {
    this._log("trace", ...args);
  }

  fatal(...args: any[]): void {
    this._log("fatal", ...args);
  }
}

const logger = new CustomLogger();

export { logger };
